import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
// import Review from "../components/reviews/review";
import { review, content, header } from "../css/reviews.module.css";

const timeString = (year, month) => {
  if (month != null) {
    return `${month} ${year}`;
  } else {
    return `${year}`;
  }
};

const monthNumbers = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const machineTimeString = (year, month) => {
  const monthNumber = monthNumbers[month];
  if (monthNumber != null) {
    return `${year}-${monthNumber}`;
  } else {
    return `${year}`;
  }
};

const MaybeFooter = ({ year, month }) => {
  if (year != null) {
    return (
      <footer>
        <time dateTime={machineTimeString(year, month)}>
          {timeString(year, month)}
        </time>
      </footer>
    );
  } else {
    return <></>;
  }
};

const reviewComparator = (a, b) => {
  if (a.node.year != null && b.node.year != null) {
    const stringA = machineTimeString(a.node.year, a.node.month);
    const stringB = machineTimeString(b.node.year, b.node.month);
    if (stringA < stringB) {
      return 1;
    } else if (stringA === stringB) {
      return 0;
    } else {
      return -1;
    }
  } else if (a.node.year == null && b.node.year == null) {
    return 0;
  } else if (a.node.year == null) {
    return 1;
  } else {
    return -1;
  }
};

const ReviewPage = ({ data }) => (
  <>
    <Helmet>
      <title>Reviews | Pannal Village Hall</title>
    </Helmet>
    <div className={header}>
      <h1>Reviews</h1>
    </div>

    <div className={content}>
      {data.allStrapiReview.edges.sort(reviewComparator).map((r, i) => (
        <article className={review} key={i}>
          <ReactMarkdown source={r.node.text} />
          <MaybeFooter year={r.node.year} month={r.node.month} />
        </article>
      ))}
    </div>
  </>
);

export default ReviewPage;

export const query = graphql`
  {
    allStrapiReview {
      edges {
        node {
          id
          text
          month
          year
        }
      }
    }
  }
`;
